import './RzHeader.css';
import { useState } from 'react';
import { Link } from "react-router-dom";
import logo from './img/logo.png';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {CNavbar, CContainer, CNavbarToggler, COffcanvas, COffcanvasHeader, CCloseButton, COffcanvasBody, CNavbarNav, CNavItem, CNavLink,
} from '@coreui/react';

function RzHeader() { 
  const [visible, setVisible] = useState(false)
  return (
    <CNavbar>
      <CContainer fluid>
      <CNavbarToggler
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
          className='burger-menu'
          onClick={() => setVisible(!visible)}
        />
        <Link to='/'><img alt="logo" src={logo} className='logo' /></Link>
        <COffcanvas id="offcanvasNavbar" placement="end" portal={false} visible={visible} onHide={() => setVisible(false)}>
          <COffcanvasHeader>
            <CCloseButton className="text-reset" onClick={() => setVisible(false)} />
          </COffcanvasHeader>
          <COffcanvasBody>
            <CNavbarNav>
              <CNavItem>
                <CNavLink>
                <Link className='nav-styling' to="/">Home</Link>
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink>
                <Link className='nav-styling' to="/ueberuns">Über Uns</Link>
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink>
                  <Link className='nav-styling' to="/galerie">Galerie</Link>
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink>
                  <Link className='nav-styling' to="/kontakt">Kontakt</Link>
                </CNavLink>
              </CNavItem>
            </CNavbarNav>
          </COffcanvasBody>
        </COffcanvas>
      </CContainer>
    </CNavbar>
  )
}

export default RzHeader;


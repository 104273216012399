import React from 'react';
import ReactDOM from 'react-dom/client';
import RzHeader from './RzHeader';
import RzBody from './RzBody';
import RzUeberUns from './RzUeberUns';
import RzGalerie from './RzGalerie';
import RzKontakt from './RzKontakt';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

const Error404 = () => <h1>404 – Seite nicht gefunden</h1>;
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<div><RzHeader />
    <RzBody /> 
  </div>} />
    <Route path="/ueberuns" element={<RzUeberUns />} />
    <Route exact path="/galerie" element={<RzGalerie />} />
    <Route exact path="/kontakt" element={<RzKontakt />} />
    <Route element={Error404} />
</Routes>
</BrowserRouter>
);



import './RzGalerie.css'; 
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RzHeader';
import './RzFooter';
import pic1 from './img/pneuBild.jpg';
import pic2 from './img/backgroundHomeFarb.jpeg';
import pic3 from './img/homepic3.jpg';
import RzFooter from './RzFooter';
import RzHeader from './RzHeader';


function RzGalerie() {  
  return (
    <div class='alles'>
      <RzHeader />
      <div class='hole-gallery'>
      <div class="gallery">
            <img src={pic1}></img>
            <img src={pic2}></img>
            <img src={pic3}></img>
      </div>
      </div>
      <div class='footer'>
      <RzFooter />      
      </div>
    </div>
  )
  }
export default RzGalerie;


import './RzBody.css';
import './RzFooter';
import 'bootstrap/dist/css/bootstrap.min.css';
import RzFooter from './RzFooter';
import backback from '../src/img/backback.png'
function RzBody() {
  return (
    <div className="rzBody">
       <div class="body-content">
          <img className='backback' src={backback} />
       <div className='texttitel'>
          <h1 class="body_titel">Garage R.Züger</h1>
            <span class="subtitle-body">Herzlich Willkommen</span>
            <br></br>
              <div class="et_pb_header_content_wrapper">
                <p>Wartung und Reparaturen an Fahrzeugen aller Automobilhersteller.
                Fachgerechte Reparaturen und schneller Service gehören zu unserem Standard. <br />
                <strong> Gemeinsam zum Ziel:
                 Ihr Fahrzeug. Unsere Kompetenz.</strong>
                </p>
                <p>
                Wir freuen uns auf Ihren Besuch und wünschen Ihnen eine gute Fahrt.
                </p>
          </div>
      </div>
          <div className='oeffnungdiv'>
      <div className='m-market-info-all'>
      <h3 className="h3-oeffnung"> Öffnungszeiten </h3>
      <ul>
        <li>
          <span>Montag:</span>
          <span class="time-span">08:00 - 18:00</span>
        </li>
        <li>
          <span>Dienstag:</span>
          <span class="time-span">08:00 - 18:00</span>
        </li>
        <li>
          <span>Mittwoch:</span>
          <span class="time-span">08:00 - 18:00</span>
        </li>
        <li>
          <span>Donnerstag:</span>
          <span class="time-span">08:00 - 18:00</span>
        </li>
        <li>
          <span>Freitag:</span>
          <span class="time-span">08:00 - 18:00</span>
        </li>
        <li>
          <span>Samstag:</span>
          <span class="time-span">Geschlossen</span>
        </li>
        <li>
          <span>Sonntag:</span>
          <span class="time-span">Geschlossen</span>
        </li>
      </ul>
      </div>
      </div>
      </div>
      <RzFooter  />
      </div>
  );
}
export default RzBody;

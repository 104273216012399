import './UeberUnsstyle.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import RzFooter from './RzFooter';
import RzHeader from './RzHeader';
import { Link } from "react-router-dom";

function RzUeberUns() {  
  return (
    <div>
      <RzHeader />    
      <div class="profile-image">
        </div>
      <div class="container">
      <h2 class="body_titel">Über Uns</h2>
        <div class="about-us">
            <p>Die Garage R.Züger wurde im Jahre 1999 in Schlieren gegründet. Nach vierjähriger Anstellung bei der Garage R.Roth übernahm Herr Renato Züger die Werkstatt von seinem ehemaligen Arbeitgeber.
            <br />
            <br />
Seine vierjährige Ausbildung zum Kfz-Mechatroniker und sein Handelsschulabschluss bilden eine gesunde Basis zur Führung des Betriebs. Unterstützt wird er durch seinen langjährigen und erfahrenen Mechaniker.Unser Unternehmen setzt sich zum Grundsatz, die Kundschaft nach bestem Wissen und Gewissen rund ums Auto zu beraten. Wir garantieren eine seriöse Ausführung der Arbeiten in unserer stets gepflegten und mit hoher Fachkompetenz geführten Werkstatt.</p>
          <Link class='linktobtn' to="/kontakt"><input type="submit" value="Kontaktieren Sie uns" /></Link>
        </div>
    </div>
    <RzFooter />
    </div>
  )
}

export default RzUeberUns;


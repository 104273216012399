import './RzFooter.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import logo from './img/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';


function RzFooter() {  
  return (
      <footer class="footer-distributed">
			<div class="footer-left">
        <img alt="logo" src={logo} className='logobody' />
				<p class="footer-links">
					<a href="/" class="link-1">Home</a>
					<a href="/ueberuns">Über Uns</a>
					<a href="/galerie">Galerie</a>
					<a href="/kontakt">Kontakt</a>
				</p>
			</div>
			<div class="footer-center">
			</div>
			<div class="footer-right">
      <div>
					<i class="fa fa-map-marker"></i>
					<p><span>Bernstrasse 27</span> 
					<br />
					8952 Schlieren</p>
				</div>
				<div>
					<i class="fa fa-phone"></i>
					<p>+41 44 731 19 15</p>
				</div>
				<div>
					<i class="fa fa-envelope"></i>
					<p><a href="mailto:garagezueger@me.com">garagezueger@me.com</a></p>
				</div>
			</div>
		</footer>
  )
  }
export default RzFooter;


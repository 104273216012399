import './RzKontakt.css';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RzHeader';
import './RzFooter';
import RzHeader from './RzHeader';
import RzFooter from './RzFooter';
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

function RzKontakt() {  
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_jc2m97n', 'template_7jflg5n', form.current, '1Sf8B5xIYZvA9gpOw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };
  return (
    <div>
      <RzHeader />
      <div className='kontaktAll'>
      <div class="formular">
      <h3 class="h3-kontakt">Kontakt-Formular</h3>
      <form ref={form} onSubmit={sendEmail}>
      <label for="name">Vorname:</label>
      <input type="text" id="name" name="user_name" required />
      <label for="vorname">Name:</label>
      <input type="text" id="vorname" name="user_prename" required />
      <label for="email">E-Mail:</label>
      <input type="email" id="email" name="user_email" required />
      <label for="nachricht">Nachricht:</label>
      <textarea id="nachricht" name="message" rows="5" required></textarea>
      <input type="submit" value="Absenden" /> 
    </form>
    </div>
     </div>
     <RzFooter />
    </div>
  )
}

export default RzKontakt;

